import "./Home.css";
import scrolldown from "../assets/images/scroll-down.gif";
import { homeProps } from "../types/Home.types";
import React, {
  createRef,
  useRef,
  useEffect,
  useState,
  cloneElement,
} from "react";
import { mappingObj } from "../utils/Mapping";
import { componentMapping } from "../types/ComponentMapping.types";
import useWindowDimensions from "../utils/useWindowDimensions";

export default function Home(props: homeProps) {
  const mapping: componentMapping = mappingObj;
  // Get menu items (keys from hashmap)
  const menuItems = Object.keys(mapping);
  // Create dynamic refs for each menu item
  const elementsRef = useRef(menuItems.map(() => createRef()));
  const [currentEntry, setCurrentEntry] = useState("");
  const { height, width } = useWindowDimensions();

  const initObserver = (
    refs: React.MutableRefObject<React.RefObject<Element>[]>
  ) => {
    // don't use intersection observer on small screens as the headers that are tracked will be hidden
    if (width <= 768) {
      return;
    }
    // init intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          let id = entry.target.id;
          setCurrentEntry(id);
          // if intersecting, add visible to classlist so the headers will animate
          let anchorHeader =
            entry.target.firstElementChild?.firstElementChild?.children[0];
          let smlg = anchorHeader?.children;
          // small text
          let sm = smlg![0];
          sm.classList.add("visibleSm");
          // large text
          let lg = smlg![1];
          lg.classList.add("visibleLg");
        }
      },
      {
        rootMargin: `0px 0px -${window.innerHeight / 2}px 0px`,
      }
    );
    refs.current.forEach((ref) => {
      observer.observe(ref.current!);
    });
  };

  useEffect(() => {
    elementsRef != undefined &&
      initObserver(
        elementsRef as React.MutableRefObject<React.RefObject<Element>[]>
      );
  }, []);

  return (
    <div className={`content ${props.isDarkMode && "dark"}`}>
      <div className="hero">
        <h1 className="title">S. Crisan,</h1>
        <h2 className="subtitle">For your IT solutions.</h2>
        <a href="#about-me">
          <img
            src={scrolldown}
            alt="scroll-down"
            className={props.isDarkMode ? "scrollImgDark" : "scrollImg"}
          ></img>
        </a>
      </div>
      <>
        {menuItems.map((item, index) => {
          //clone the element so we can pass props to the variable, then render cloned element
          let component = cloneElement(
            mapping[item as keyof componentMapping],
            { id: item, reversed: false, isDarkMode: props.isDarkMode }
          );
          return (
            <div
              className="row"
              ref={elementsRef.current[index] as any}
              key={index}
            >
              {component}
            </div>
          );
        })}
      </>
    </div>
  );
}
