import AnchorHeader from "./AnchorHeader";
import AnchorHeaderMobile from "./AnchorHeaderMobile";
import "./MyWork.css";
import { sectionProps } from "../types/SectionProps";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import DiscordIcon from "./DiscordIcon";
import TwitterIcon from "./TwitterIcon";
import LinkedInIcon from "./LinkedInIcon";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GithubIcon from "./GithubIcon";
import Snackbar from "@mui/material/Snackbar";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Button, Form } from "react-bootstrap";

export default function Contact(props: sectionProps) {
  const { width } = useWindowDimensions();

  // emailjs
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const pubKey = process.env.REACT_APP_PUBLIC_KEY;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const form: any = useRef();
  const userName: any = useRef();
  const userEmail: any = useRef();
  const userMessage: any = useRef();

  const sendEmail = (e: any) => {
    e.preventDefault();
    setLoading(true);
    emailjs.sendForm(serviceId!, templateId!, form.current, pubKey).then(
      (result) => {
        setLoading(false);
        setMsg(
          "Message received! 🤩 I usually answer within 24 hours. Thank you!"
        );
      },
      (error) => {
        setLoading(false);
        setMsg(error.text);
      }
    );
  };

  //success message snackbar
  const [open, setOpen] = useState(false);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  //copy text to clipboard upon click
  const [value, setValue] = useState("");
  const copyToClipboard = async (text: string) => {
    setValue(text);
    await navigator.clipboard.writeText("Gaiseric#1574");
    // show success message
    setOpen(true);
  };

  return (
    <>
      <div className="cols">
        <div className="left">
          {width > 768 && (
            <AnchorHeader lg={"Contact"} sm={""} id={props.id}></AnchorHeader>
          )}
        </div>
        <div className="center contact">
          {width <= 768 && (
            <AnchorHeaderMobile
              lg={"Contact"}
              sm={""}
              id={props.id}
            ></AnchorHeaderMobile>
          )}
          <div className="centerHeader">
            <div className="socials">
              <div
                className="socialIcon"
                onClick={() => {
                  copyToClipboard("Gaiseric#1574");
                }}
              >
                <DiscordIcon
                  color={props.isDarkMode ? "#379997" : "#1a4d4c"}
                  width={40}
                  height={40}
                ></DiscordIcon>
                <h2 className={props.isDarkMode ? "dark" : "light"}>
                  Gaiseric#1574
                </h2>
              </div>
              <a
                className="socialIcon"
                href="https://twitter.com/GaisericDev"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon
                  height={40}
                  width={40}
                  color={props.isDarkMode ? "#379997" : "#1a4d4c"}
                ></TwitterIcon>
                <h2 className={props.isDarkMode ? "dark" : "light"}>Twitter</h2>
              </a>
              <a
                className="socialIcon"
                href="https://www.linkedin.com/in/sebastiaan-crisan-b8325b153/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon
                  height={40}
                  width={40}
                  color={props.isDarkMode ? "#379997" : "#1a4d4c"}
                ></LinkedInIcon>
                <h2 className={props.isDarkMode ? "dark" : "light"}>
                  LinkedIn
                </h2>
              </a>
              <a
                className="socialIcon"
                href="https://github.com/GaisericDev"
                target="_blank"
                rel="noreferrer"
              >
                <GithubIcon
                  height={40}
                  width={40}
                  color={props.isDarkMode ? "#379997" : "#1a4d4c"}
                ></GithubIcon>
                <h2 className={props.isDarkMode ? "dark" : "light"}>GitHub</h2>
              </a>
            </div>
            <h1>Send me a message 👇</h1>
          </div>
          <Form
            className={`contactForm ${props.isDarkMode && "dark"}`}
            ref={form}
            onSubmit={sendEmail}
          >
            <Form.Group id="data">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="input"
                ref={userName}
                name="user_name"
                required
              ></Form.Control>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={userEmail}
                name="user_email"
                required
              ></Form.Control>
              <Form.Label>Message</Form.Label>
              <textarea
                className="form-control txtArea"
                name="message"
                ref={userMessage}
                required
              ></textarea>
            </Form.Group>
            <Button
              type="submit"
              disabled={loading}
              className={`sendButton ${props.isDarkMode ? "dark" : "light"}`}
            >
              {loading ? <span className="lds-dual-ring"></span> : "Send"}
            </Button>
          </Form>
          <h3>{msg}</h3>
        </div>
        <div className="right"></div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`Copied ${value} to clipboard`}
        action={action}
      />
    </>
  );
}
