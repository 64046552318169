import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Switch from '@mui/material/Switch';
import {AppbarProps, menuItems} from "../types/Appbar.types";
import {Brightness7, Brightness4} from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import "./Appbar.css";
import { useState } from 'react';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
 
export default function ButtonAppBar(props:AppbarProps) {
  const label = { inputProps: { 'aria-label': 'Switch' } };
  const menuData:menuItems = [{text: "About", link: "#about-me"}, {text: "My Work", link: "#my-work"}, {text: "Contact", link: "#contact"}]
  type Anchor = 'right';
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
    const list = (anchor: Anchor) => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {menuData.map(d => (
            <a href={d.link} key={d.text}>
              <ListItem button>
                <ListItemText sx={{ color: props.check? "#FAFAFA" : "#212121"}} primary={d.text}/>
              </ListItem>
            </a>
          ))}
        </List>
        <Divider />
      </Box>
    );
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color="default" elevation={0}>
        <Toolbar >
          <div className='toggler'>
            <Brightness7 color="inherit"></Brightness7>
            <Switch {...label} color="default" onChange={props.change} checked={props.check} />
            <Brightness4 color="inherit" sx={{ flexGrow: 1 }}></Brightness4>
          </div>
          {menuData.map(d => (
            <a href={d.link} key={d.text}>
               <Typography
              key={d.text}
              variant="h6"
              color={props.check ? "#FAFAFA" : "#212121"}
              noWrap
              component="div"
              sx={{display: { xs: 'none', sm: 'block' }, marginRight: "15px" }}>
                {d.text}
              </Typography>
            </a>
          ))}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 , display: {
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none"
            }}}
            onClick={toggleDrawer('right', true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {list('right')}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
