import {useRef} from 'react'
import { anchorHeaderProps } from '../types/AnchorHeader.types'
import "./AnchorHeaderMobile.css";

export default function AnchorHeaderMobile(props: anchorHeaderProps) {
  return (
    <div className="anchorHeaderMobile" id={props.id}>
        <div className={props.reversed ? "lgMobile" : "smMobile"}>{props.sm}</div>
        <div className={props.reversed ? "smMobile" : "lgMobile"}>{props.lg}</div>
    </div>
  )
}