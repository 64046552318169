import React from 'react'
import "./workCard.css"

type Props = {}

export default function MerkleDash({}: Props) {
  return (
    <div className="workContainer">
      <div className="workDataRow">Project Name: Merkle Dash</div>
      <div className="workDataRow">Tech : Merkle Dash</div>
    </div>
  )
}