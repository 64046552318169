import {useRef} from 'react'
import { anchorHeaderProps } from '../types/AnchorHeader.types'
import "./AnchorHeader.css";

export default function AnchorHeader(props: anchorHeaderProps) {
  return (
    <div className="anchorHeader" id={props.id}>
        <div className={props.reversed ? "lg" : "sm"}>{props.sm}</div>
        <div className={props.reversed ? "sm" : "lg"}>{props.lg}</div>
    </div>
  )
}