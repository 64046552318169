import pfp from "../assets/images/pfp.jpg";
import AnchorHeader from "./AnchorHeader";
import AnchorHeaderMobile from "./AnchorHeaderMobile";
import "./About.css";
import scrolldown from "../assets/images/scroll-down-2.gif";
import { sectionProps } from "../types/SectionProps";
import useWindowDimensions from "../utils/useWindowDimensions";

export default function About(props: sectionProps) {
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="cols">
        <div className="left">
          {width > 768 && (
            <AnchorHeader lg={"About"} sm={""} id={props.id}></AnchorHeader>
          )}
        </div>
        <div className="center about">
          {width <= 768 && (
            <AnchorHeaderMobile
              lg={"About"}
              sm={""}
              id={props.id}
            ></AnchorHeaderMobile>
          )}
          <img src={pfp} className="avatar" alt="Profile"></img>
          <p>
            Hi, nice to meet you! My name is Sebastiaan, but just call me Seb. I
            am located in the Netherlands. I have 3 years of experience in IT
            since September 2021.
            <br></br>
            <br></br>
            My services include, but are not limited to:<br></br>
            <br></br>- <i>Web2 Apps (regular websites)</i>
            <br></br>- <i>Web3 Dapps such as marketplaces, DeFi apps</i>
            <br></br>-{" "}
            <i>Automation (Backend scripting, Google Sheets, Data Scraping)</i>
            <br></br>- <i>Web3 Dapps such as marketplaces, DeFi apps</i>
            <br></br>
          </p>
          <a href="#my-work">
            <img
              src={scrolldown}
              alt="scroll-down"
              className={props.isDarkMode ? "scroll2ImgDark" : "scroll2Img"}
            ></img>
          </a>
        </div>
        <div className="right"></div>
      </div>
    </>
  );
}
