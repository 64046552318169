import React, { useState } from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ButtonAppBar from './components/Appbar';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {
  //disables console log 
  console.log = console.warn = console.error = () => {};

  const [darkMode, setDarkMode] = useState(true);
  
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light"
    }
  });
  
  return (
    
      <div className="App">
        <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ButtonAppBar check={darkMode} change={()=>setDarkMode(!darkMode)}></ButtonAppBar>
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path="/" element={<Home isDarkMode={darkMode}/>}></Route>
          </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    
  );
}
export default App;

