import AnchorHeader from "./AnchorHeader";
import AnchorHeaderMobile from "./AnchorHeaderMobile";
import "./MyWork.css";
import { workList } from "../types/MyWork.types";
import { sectionProps } from "../types/SectionProps";
import CursorPunks from "./works/CursorPunks";
import MerkleDash from "./works/MerkleDash";
import MferHeads from "./works/MferHeads";
import SamsaraRebels from "./works/SamsaraRebels";
import TokenSniper from "./works/TokenSniper";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import GithubIcon from "./GithubIcon";
import useWindowDimensions from "../utils/useWindowDimensions";
export default function MyWork(props: sectionProps) {
  const { width } = useWindowDimensions();

  const workList: workList = {
    "Merkle Dash": {
      project: <MerkleDash></MerkleDash>,
      link: "https://f5771c9e.merkledash-v2.pages.dev/#/",
      github: "https://github.com/sebcrisan/merkledash-v2",
      description:
        "A dashboard where users can create projects (upload a list of items), and use API endpoints to retrieve the merkle root and proof of those projects.",
      tech: ["React", "SASS", "Firebase", "Node.js", "Vite"],
    },
    "Token Sniper": {
      project: <TokenSniper></TokenSniper>,
      link: "https://github.com/sebcrisan/uniswapv2pairlistener",
      github: "https://github.com/sebcrisan/uniswapv2pairlistener",
      description:
        "Listens for new trading pairs on Uniswap V2 and logs them to a file.",
      tech: ["Node.Js"],
    },
    "Samsara Rebels": {
      project: <SamsaraRebels></SamsaraRebels>,
      link: "https://samsara-9a5.pages.dev/",
      github: "",
      description:
        "NFT startup where I worked as a dev to write the smart contracts and create the minting Dapp. Unfurtunately, the project got deprecated but parts of my work still exist.",
      tech: ["React", "Typescript", "Node.Js", "Solidity", "Hardhat", "Python"],
    },
    MferHeads: {
      project: <MferHeads></MferHeads>,
      link: "https://opensea.io/collection/mferheads",
      github: "",
      description:
        "NFT collection that I made with a friend, derivative of Mfers. Got #1 on icy tools for a bit without any marketing before it died down.",
      tech: ["Vue", "Solidity", "Hardhat", "Python"],
    },
    CursorPunks: {
      project: <CursorPunks></CursorPunks>,
      link: "https://opensea.io/collection/cursor-punks",
      github: "",
      description:
        "NFT collection that I made with a friend. Made some mistakes here, but learned a lot from it. If we had minted out, we would develop a browser extension that would enable users to use their NFT's as a cursor.",
      tech: ["Vue", "Solidity", "Hardhat", "Python"],
    },
  };

  const workListKeys = Object.keys(workList);

  return (
    <>
      <div className="cols">
        <div className="left">
          {width > 768 && (
            <AnchorHeader lg={"Work"} sm={"MY"} id={props.id}></AnchorHeader>
          )}
        </div>
        <div className="center work">
          {width <= 768 && (
            <AnchorHeaderMobile
              lg={"Work"}
              sm={"MY"}
              id={props.id}
            ></AnchorHeaderMobile>
          )}
          {workListKeys.map((item, index) => (
            <a
              href={workList[item as keyof workList].link}
              target="_blank"
              key={index}
            >
              <div className={`card ${props.isDarkMode && "dark"}`}>
                <div className="cardHeader">
                  <div className="icon">
                    <FolderOutlinedIcon
                      sx={{ height: 40, width: 40 }}
                    ></FolderOutlinedIcon>
                  </div>
                  <div className="spacer"></div>
                  <a
                    className={`icon ${props.isDarkMode && "dark"}`}
                    href={workList[item as keyof workList].github}
                    target="_blank"
                  >
                    {workList[item as keyof workList].github != "" && (
                      <GithubIcon
                        color={props.isDarkMode ? "white" : "black"}
                        width={40}
                        height={40}
                      ></GithubIcon>
                    )}
                  </a>
                </div>
                <div className="cardTitle">{item}</div>
                <div className="cardDesc">
                  {workList[item as keyof workList].description}
                </div>
                <div className="tech">
                  {workList[item as keyof workList].tech.map((item, index) => {
                    return (
                      <div key={index} className="techItem">
                        <i>{item}</i>
                      </div>
                    );
                  })}
                </div>
              </div>
            </a>
          ))}
        </div>
        <div className="right"></div>
      </div>
    </>
  );
}
