import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';

export default function NotFound() {
  const container = {
    minHeight: "100vh",
  }
  const card = {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }

    return (
      <div className='content'>
        <Grid container sx={container}>
          <Grid item xs={12} sx={card}>
            <h1>404 Not Found!</h1>
            <Typography variant="body2" color="text.primary">
                Oops, the content you're trying to view was not found!
            </Typography> 
          </Grid>
        </Grid>
      </div> 
  )
}